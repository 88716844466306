// @font-face {
//   font-family: "Sarabun";
//   src: local("Sarabun"),
//     url(../assets/fonts/Sarabun-Light.ttf) format("truetype");
// }

// @font-face {
//   font-family: "Sarabun-SemiBold";
//   src: local("Sarabun-SemiBold"),
//     url(../assets/fonts/Sarabun-SemiBold.ttf) format("truetype");
// }

// @font-face {
//   font-family: "Sarabun-Bold";
//   src: local("Sarabun-Bold"),
//     url(../assets/fonts/Sarabun-Bold.ttf) format("truetype");
// }

// @font-face {
//   font-family: "Sarabun-Regular";
//   src: local("Sarabun-Regular"),
//     url(../assets/fonts/Sarabun-Regular.ttf) format("truetype");
// }

// button,
// input,
// select,
// textarea,
// a,
// p,
// label,
// span,
// td,
// tr,
// div {
//   font-family: Sarabun;
// }

// h1,
// h2,
// h3 {
//   font-family: Sarabun-SemiBold;
// }

// h4,
// h5 {
//   font-family: Sarabun-Bold;
// }

// .form-group label {
//   font-family: Sarabun-SemiBold;
// }

@font-face {
  font-family: "Prompt";
  src: local("Prompt"),
    url(../assets/fonts/Prompt-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Prompt-SemiBold";
  src: local("Prompt-SemiBold"),
    url(../assets/fonts/Prompt-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Prompt-Bold";
  src: local("Prompt-Bold"),
    url(../assets/fonts/Prompt-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Prompt-Regular";
  src: local("Prompt-Regular"),
    url(../assets/fonts/Prompt-Regular.ttf) format("truetype");
}

button,
input,
select,
textarea,
a,
p,
label,
span,
td,
tr,
div {
  font-family: Prompt;
}

h1,
h2,
h3 {
  font-family: Prompt-SemiBold;
}

h4,
h5 {
  font-family: Prompt-Bold;
}
.form-group label {
  font-family: Prompt-SemiBold;
}

.page-item.active .page-link {
  background-color: #39f;
  border-color: #39f;
}

thead {
  background-color: lightblue;
}

.page-link {
  color: #39f;
}

.c-sidebar {
  background-color: #333333;
  //background-color: white;
}

// .c-sidebar-nav-title , .c-sidebar-nav-dropdown-toggle, .c-sidebar-nav-link {
//    color: black !important;

//  }

.c-sidebar-brand-full {
  // background-image: url('./../assets/icons/logo.png');
  width: 80px;
  // background-color: #cccccc;
}

.cursor-pointer {
  cursor: pointer;
}

.c-login-brand {
  width: 200px;
}

.c-sidebar-brand {
  padding-top: 10px;
  padding-bottom: 10px;
}

thead {
  background-color: #ffff;
  color: black;
}

table {
  border: 1.5px solid #eff1f5;
}

.breadcrumb-item {
  a {
    color: #113d64 !important;
  }
}

.draftBackGround {
  background-color: white !important;
}

.c-sidebar-nav-item-sub {
  padding-left: 8px;
  font-size: 13px;
}

.local-font {
  font-family: "Kanit", sans-serif !important;
}

.svd_container {
  margin-top: 10px;
}

.svd_container .svd_commercial_container {
  display: none !important;
}

// .svd-popup-editor-tab-general {
//   display: none !important;
// }

question-actions > div > span:nth-child(2),
question-actions > div > span:nth-child(3),
question-actions > div > span:nth-child(6),
question-actions > div > span:nth-child(8),
question-actions > div > span:nth-child(9)
// question-actions > div > span:nth-child(6),
// question-actions > div > span:nth-child(7)
{
  display: none !important;
}

svd-accordion > div > div:nth-child(3),
svd-accordion > div > div:nth-child(4),
svd-accordion > div > div:nth-child(5),
svd-accordion > div > div:nth-child(6),
svd-accordion > div > div:nth-child(7),
svd-accordion > div > div:nth-child(8),
svd-accordion > div > div:nth-child(9),
svd-accordion > div > div:nth-child(10),
svd-accordion > div > div:nth-child(11),
svd-accordion > div > div:nth-child(12),
svd-accordion > div > div:nth-child(13),
svd-accordion > div > div:nth-child(14) {
  display: none !important;
}

.svda-title-editor-placeholder {
  font-size: 20px;
  opacity: 0.8;
}

.modal-container-custom {
  display: flex !important;
}

#svd-survey-settings {
  display: none;
}

.svd-popup-editor-tab-general {
  div.form-group {
    div[data-property="visible"],
    div[data-property="readOnly"],
    div[data-property="showTitle"],
    div[data-property="locale"],
    div[data-property="mode"],
    div[data-property="cookieName"],
    div[data-property="isRequired"],
    div[data-property="hasComment"],
    // div[data-property="inputType"],
    div[data-property="autoComplete"] {
      display: none;
    }
    .svd_custom_select select {
      option[value="color"] {
        display: none !important;
      }
      option[value="datetime"] {
        display: none !important;
      }
      option[value="email"] {
        display: none !important;
      }
      option[value="month"] {
        display: none !important;
      }
      option[value="number"] {
        display: none !important;
      }
      option[value="password"] {
        display: none !important;
      }
      option[value="range"] {
        display: none !important;
      }
      option[value="tel"] {
        display: none !important;
      }
      option[value="url"] {
        display: none !important;
      }
      option[value="week"] {
        display: none !important;
      }
    }
  }
}
.svd-accordion-tab-header[data-title="Validation"] {
  display: none !important;
}
.svd-accordion-tab-header[data-title="Show on Completed"] {
  display: none !important;
}

.svd-accordion-tab-header[data-title="Timer/Quiz"] {
  display: none !important;
}

.modal-dialog-custom {
  width: 700px;
  border: 2px solid #000;
  padding: 16px 32px 24px;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  background-color: #fff;
  margin: auto;
}

.text-bold {
  font-family: "BaiJamjuree-Bold";
}

.c-sidebar.c-sidebar-light .c-sidebar-brand {
  color: #275395;
  background: #fff;
  font-weight: bold;
}

.c-sidebar.c-sidebar-light .c-sidebar-nav-link,
.c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown-toggle {
  color: #113d64;
  background: transparent;
}

.c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-link,
.c-sidebar.c-sidebar-light
  .c-sidebar-nav-dropdown.c-show
  .c-sidebar-nav-dropdown-toggle {
  color: #113d64;
  background: transparent;
}

.c-sidebar.c-sidebar-light .c-sidebar-nav-link:hover,
.c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown-toggle:hover {
  color: #fff;
  background: #113d64;
}

// .c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-dropdown-toggle:hover {
// 	color: #fff;
// 	background: #113d64;
// }
// .c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-dropdown-items .c-sidebar-nav-item.c-sidebar-nav-item-sub:hover {
// 	color: #fff;
// 	background: #113d64;
// }
// .c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-dropdown-items .c-sidebar-nav-item.c-sidebar-nav-item-sub:hover .c-sidebar-nav-link {
// 	color: #fff;
// }

a.nav-link {
  color: #113d64 !important;
}
.btn-info {
  // color: #fff;
  background-color: #113d64 !important;
  border-color: #113d64 !important;
}

.btn-outline-info {
  color: #113d64 !important;
  border-color: #113d64 !important;
}
.btn-outline-info:hover {
  color: #fff !important;
  border-color: #113d64 !important;
  background-color: #113d64 !important;
}

.c-app {
  --primary: #113d64 !important;
  --info: #113d64 !important;
}

.ant-tabs-tab-active,
.text-info {
  color: #113d64 !important;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #113d64 !important;
}

.flex-1 {
  flex: 1;
}

.text-center {
  text-align: center;
}

.ant-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.7) !important;
}

.d-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidebar-icon {
  margin-right: 20px;
  height: 1.09375rem;
}
.c-sidebar.c-sidebar-light .c-sidebar-nav-link.c-active,
.c-sidebar.c-sidebar-light .c-active.c-sidebar-nav-dropdown-toggle {
  background-color: #113d64 !important;
  color: #fff !important;
}

span.tag-wrapper.ReactTags__tag {
  padding: 2px 10px !important;
  border-radius: 5px !important;
  background: #efefef !important;
  margin-right: 10px !important;
  font-size: 14px !important;
  .ReactTags__remove {
    border: none !important;
    line-height: 0.8px;
    font-size: 16px !important;
    padding: 0 !important;
    color: #979797;
  }
}

.sv_complete_btn {
  display: none !important;
}

#save-survey > div {
  color: #fff !important;
  background-color: #28a745 !important;
}

.ql-editor{
  min-height: 200px !important;
}